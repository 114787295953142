// Layout.js
import React from 'react';
import Navbar from './Navbar';

const Layout = ({ children, containerClass = '' }) => {
  return (
    <div className={containerClass}>
      <Navbar /> {/* Navbar inside .about-container */}
      {children}
    </div>
  );
};

export default Layout;
