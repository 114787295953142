import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import './Navbar.css';

const Navbar = () => {
    const location = useLocation();
    const [activeLanguage, setActiveLanguage] = useState('ID');
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); 
    };

    useEffect(() => {
        handleResize(); 
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleLanguageClick = (language) => {
        setActiveLanguage(language);
    };

    return (
        <nav className="navbar navbar-expand-lg custom-navbar">
            <div className="container d-flex justify-content-between">
                <img src="/img/Logo Kelanara.png" alt="Kelanara Logo" className="navbar-brand-logo" />

                <div className="collapse navbar-collapse justify-content-center" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        {['home', 'about', 'kelanara+®', 'media', 'contact'].map((link) => (
                            <Link
                                key={link}
                                className={`nav-link ${location.pathname === `/${link === 'home' ? '' : link}` ? 'active glow underline' : ''}`}
                                to={`/${link === 'home' ? '' : link}`}
                            >
                                {link.charAt(0).toUpperCase() + link.slice(1)}
                            </Link>
                        ))}
                    </div>
                </div>

                {!isMobile && (
                    <div className="navbar-nav language-toggle">
                        <span
                            className={`nav-link ${activeLanguage === 'EN' ? 'active glow' : ''}`}
                            onClick={() => handleLanguageClick('EN')}
                        >
                            EN
                        </span>
                        <div className="language-separator"></div>
                        <span
                            className={`nav-link ${activeLanguage === 'ID' ? 'active glow' : ''}`}
                            onClick={() => handleLanguageClick('ID')}
                        >
                            ID
                        </span>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Navbar;