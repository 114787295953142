import Layout from "../Layout";
import React from "react";
import '../../App.css';
import Footer from "../Footer";
import Contactbutton from "../Contactbutton";

const Contact = () => {
    return(
        <>
            <Layout containerClass="contact-wrapper"></Layout>
                <div className="form">
                    <div className="form-info-wrapper">
                        <div className="form-input">
                            <h2 className="cta">Let's Start a Conversation</h2>
                            <p className="desc">Hubungi kami sekarang dan wujudkan<br />ide kreatif Anda bersama<br />Kelanara Studio!</p>
                            <div className="row">
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="Fullname" aria-label="Fullname" />
                                </div>
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="Organization" aria-label="Organization" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="Email" aria-label="Email" />
                                </div>
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="Phone" aria-label="Phone" />
                                </div>
                            </div>
                            <div className="col">
                                <input type="text" className="form-control" placeholder="Additional Message" aria-label="Additional Message" />
                            </div>
                            <Contactbutton />
                        </div>
                        <div className="org-info">
                            <div className="whatsapp-contact">
                                <p className="text">Whatsapp</p>
                                <p className="text">+62 2150955747</p>
                            </div>
                            <div className="email-contact">
                                <p className="text">Email</p>
                                <p className="text">kelanarastudio@gmail.com</p>
                            </div>
                            <div className="socmed-icon">
                                <p className="text">Follow Us</p>
                                <div className="social-media">
                                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                        <img src="/icon/facebook-grey.png" alt="facebook-icon" className="icon" />
                                    </a>
                                    <a href="https://www.instagram.com/kelanarastudio/" target="_blank" rel="noopener noreferrer">
                                        <img src="/icon/instagram-grey.png" alt="instagram-icon" className="icon" />
                                    </a>
                                    <a href="https://x.com/KelanaraStudio" target="_blank" rel="noopener noreferrer">
                                        <img src="/icon/twitter-grey.png" alt="twitter-icon" className="icon" />
                                    </a>
                                    <a href="https://www.youtube.com/@KelanaraStudio/featured" target="_blank" rel="noopener noreferrer">
                                        <img src="/icon/youtube-grey.png" alt="youtube-icon" className="icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    )
}

export default Contact;