import React from "react";
import { Link } from "react-router-dom"; // Import Link
import './Aboutbutton.css';

const Aboutbutton = () => {
    return (
        <div className="button">
            <img src="/icon/read more.png" alt="read-more-icon" className="read-more-icon" />
            <Link to="/about" className="link">Read More</Link>
        </div>
    );
}

export default Aboutbutton;